import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Aux from '../../hoc/Aux';
import { NavLink } from 'react-router-dom';
import '../navigation/Navigation.css';
import logo from '../../asserts/images/logo.svg';
import liveImage from '../../asserts/images/live.svg';

const NavigationItems = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [expandedMenubar, setExpandedMenubar ] = useState(false);

    function navMenuexpanding() {
        setExpanded(!expanded)
    }
    return (
        <Aux>
            <nav className="navbar fixed-top bg-nav-color justify-content-between navbar-expand-lg">
                <div className="logo-contain">
                    <span className="navbar-brand logo" >
                        <img src={logo} alt="logo" />
                    </span>
                </div>
                <div className="nav-icon-sm" >
                <a target="_blank" href="https://www.youtube.com/c/JESUSWITHUSTondairpet/videos?view=2&live_view=503">
                        <img src={liveImage} alt="live Icon" title="Go to Live" className="liveIcon" />
                    </a>
                   <a href="https://m.facebook.com/Jesus-With-Us-Church-Chennai-103215068159505/?ref=bookmarks"><FontAwesomeIcon icon={faFacebookF} className="socialIcon ml-4" /></a>   
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon icon={expandedMenubar ? faTimes : faBars } onClick={()=> setExpandedMenubar(!expandedMenubar)} className="menuIcon ml-1" />
                </button>               
                </div>
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon={faBars} className="menuIcon ml-4" />
                </button> */}

                <div className={`collapse navbar-collapse ${expanded ? 'menuHide' : null }`} id="navbarSupportedContent" >
                    <ul className="navbar-nav mr-auto " onClick={() => navMenuexpanding()}>
                        {/* <li className="nav-item">
                            <Link to={props.to} className="nav-link">{props.menu}</Link>
                        </li> */}
                        <li className="nav-item">
                            <NavLink to="/" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">HOME</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/About-us" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">ABOUT US</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Ministries" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">MINISTRIES</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Media" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">MEDIA</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Prayer-Request" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">PRAYER REQUEST</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/Giving" exact className="nav-link nav-menu" onClick={()=> setExpandedMenubar(false)} activeClassName="active-menu">GIVING</NavLink>
                        </li>
                    </ul>
                </div>

                <div className="nav-icon">
                    <a target="_blank" href="https://www.youtube.com/c/JESUSWITHUSTondairpet/videos?view=2&live_view=503">
                        <img src={liveImage} alt="live Icon" title="Go to Live" className="liveIcon" />
                    </a>
                    <a target="_blank" href="https://m.facebook.com/Jesus-With-Us-Church-Chennai-103215068159505/?ref=bookmarks">
                        <FontAwesomeIcon icon={faFacebookF} className="socialIcon ml-4" />
                     </a>                 
                </div>
            </nav>
        </Aux>
    )
}

export default NavigationItems;