import React from 'react';
import Aux from '../../../hoc/Aux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../../responsive.css';
import '../../../containers/Ministries/Ministries.css';
import Underline from '../../../asserts/images/header_line.svg';
import ChildrenMinistry1 from '../../../asserts/images/OurMinistries/CM_1.png';
import ChildrenMinistry2 from '../../../asserts/images/OurMinistries/CM_2.png';
import ChildrenMinistry3 from '../../../asserts/images/OurMinistries/CM_3.png';
import ChildrenMinistry4 from '../../../asserts/images/OurMinistries/CM_4.png';
import ChildrenMinistry5 from '../../../asserts/images/OurMinistries/CM_5.png';
import ChildrenMinistry6 from '../../../asserts/images/OurMinistries/CM_6.png';

const ChildrenMinistries = () => {

    const options = {
        items: 3,
        nav: true,
        rewind: true,
        autoplay: true,
        autoplayHoverPause: true,
        slideBy: 1,
        dots: false,
        loop: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            576: {
                items: 1
            },
            992: {
                items: 3

            }

        }

    }

    return (

        <Aux>
            <section className="mt-5 spx-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <div className="d-inline-block">
                                    <div className="children-ministry-title">CHILDREN MINISTRIES</div>
                                    <img  className="title-underline-img" src={Underline} alt="underline" />
                                    <p className="children-ministry-subtitle mt-3">
                                        Train up a child in the way he should go,
                                        and when he is old he will not depart from it - Proverbs 22:6
                                    </p>
                                </div>
                            </div>

                            <p className="children-ministry-details">
                                I firmly believe that children play a very vital role in the growth
                                of any church. And for this reason we started a Sunday school from the early
                                days of the church. What started as a small group has grown steadily and is
                                currently functioning with a strength of around 300 under the name Jesus With
                                Us Bible Academy (JEBA). The course is designed in a way so as to carter to the
                                children according to their age and understanding abilities. By the end of the
                                course each child will have a basic understanding of the bible that includes
                                a) Repentance, b) Salvation, c) Baptism and d) Proclaiming the gospel.
                            </p>

                            <p className="children-ministry-details">
                                The kids end their Sunday school course each year with a cultural fest.
                                We also conduct Vacation Bible School (VBS) every year for the children
                                who do not know about Jesus. Every year around 1000 children participate
                                in VBS. We also have a mechanism to follow-up on the children and many have
                                come to know the Lord, are regular to church and we have had instances where
                                children from the VBS have become Sunday school teachers in the church. The
                                Sunday school and the VBS are supported by a group of 30 teachers. I also
                                conduct teachers meeting every month to encourage and motivate the teachers
                                and I also facilitate a special yearly program where we get resource
                                personnel in the children’s ministry to provide training for
                                the Sunday school teachers.
                            </p>
                        </div>
                    </div>


                    <div className="row ministries-small-hidden">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry1} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry2} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry3} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry4} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry5} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry6} alt="Children Ministries" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="spx-2">
                <div className='container-fluid outreach-slider' >
                    <OwlCarousel className="owl-theme ministry-slider-card" margin={8} {...options}>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry1} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry2} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry3} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry4} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry5} alt="Children Ministries" />
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 children-ministry-img mt-3">
                            <img src={ChildrenMinistry6} alt="Children Ministries" />
                        </div>
                    </OwlCarousel>
                </div>
            </section>
        </Aux>
        
    )
}

export default ChildrenMinistries;