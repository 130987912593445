import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../MinistrySlider/MinistrySlider.css'
import Outreach from '../../../asserts/images/HomePage/Gospel_Outreach.png';
import Children from '../../../asserts/images/HomePage/Children_Ministry.png';
import Youth from '../../../asserts/images/HomePage/Youth_Ministry.png';
import Family from '../../../asserts/images/HomePage/Family_Fellowship.png';
import Underline from '../../../asserts/images/header_line.svg';

class MinistrySlider extends Component {

    state = {
        options: {
            items: 3,
            nav: true,
            rewind: true,
            autoplay: true,
            autoplayHoverPause: true,
            slideBy: 1,
            dots: false,
            loop: true,
            // arrows: false,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                576: {
                    items: 1
                },
                992: {
                    items: 3

                }

            }

        }
    }


    render() {

        return (

            <section className="mb-4 spx-5">
                <div className="container-fluid">
                    <div className="row ministry-row">
                        <div className="col-12 text-center">
                            <div className="ministry-header "> OUR MINISTRIES</div>
                            <img className="d-inline-block mb-5 title-underline-img" src={Underline} alt="underline" />
                        </div>
                    </div>
                </div>



                <div className='container-fluid' >

                    <OwlCarousel className="owl-theme ministry-slider-card" margin={8} {... this.state.options}>
                        <div className="card">
                            <img className="card-img-top p-3" src={Outreach} alt="Outreach Ministry " />
                            <div className="card-body">
                                <div className="card-title">GOSPEL OUTREACH MINISTRIES</div>
                                <p className="card-text">As church we use to reach different villages
                                every year in various part of Tamil Nadu and conduct gospel meeting for
                                three day to preach the gospel of our Lord </p>
                                <NavLink to="/Ministries" exact className="text-decoration float-right">More</NavLink>

                            </div>
                        </div>


                        <div className="card item">
                            <img className="card-img-top p-3" src={Children} alt="Children Ministry" />
                            <div className="card-body">
                                <div className="card-title">CHILDREN MINISTRIES</div>
                                <p className="card-text">We firmly believe that children play a very vital role in the growth of any church.
                                And for this reason we started a Sunday school from the early days of the church...</p>
                                <NavLink to="/Ministries" exact className="text-decoration float-right">More</NavLink>
                            </div>
                        </div>


                        <div className="card">
                            <img className="card-img-top p-3" src={Youth} alt="Youth Ministry" />
                            <div className="card-body">
                                <div className="card-title">YOUTH MINISTRIES</div>
                                <p className="card-text">The main goal of the youth meeting at Jesus With Us,
                                Tondiarpet is Holy living and becoming true disciples of Christ.With this goal
                                in mind youth meetings are conducted </p>
                                <NavLink to="/YouthMinistries" exact className="text-decoration float-right">More</NavLink>
                            </div>
                        </div>

                        <div className="card">
                            <img className="card-img-top p-3" src={Family} alt="Family Fellowship" />
                            <div className="card-body">
                                <div className="card-title">FAMILY FELLOWSHIP</div>
                                <p className="card-text">The bible says in the book of Acts chapter two that
                                the early Christians met in each other’s houses and praised the lord and the
                                Lord added to the church those who are being saved.</p>
                                <NavLink to={{pathname:'/Ministries', hash:'#Family'}} exact className="text-decoration float-right">More</NavLink>
                            </div>
                        </div>

                    </OwlCarousel>
                </div>

            </section>
        )
    }


}

export default MinistrySlider;