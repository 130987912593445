import React from 'react';
import './App.css';
import Layout from './components/Layout/Layout';
import Aux from './hoc/Aux';
import ScrollTop from './hoc/ScrollTop';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import Home from './containers/Home/home';
import AboutUs from './containers/AboutUs/AboutUs';
import Giving from './containers/Giving/Giving';
import Media from './containers/Media/Media';
import PrayerReq from './containers/PrayerRequest/PrayerRequest';
import Ministries from './containers/Ministries/Ministries';
function App() {
  return (
    <HashRouter>
      <Aux>
        <Layout />
      </Aux>
      <ScrollTop>
        <Switch>
          <Route path="/" exact strict component={Home} />
          <Route path="/About-us" exact component={AboutUs} />
          <Route path="/Media" exact component={Media} />
          <Route path="/Ministries" exact component={Ministries} />
          <Route path="/Prayer-Request" exact component={PrayerReq} />
          <Route path="/Giving" exact component={Giving} />
          <Route path="**" component={Home} />
        </Switch>
      </ScrollTop>
    </HashRouter>

  );
}

export default App;
