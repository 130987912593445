import React from 'react';
import Aux from '../../../hoc/Aux';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../../responsive.css';
import '../../../containers/Ministries/Ministries.css';
import Underline from '../../../asserts/images/header_line.svg';
import FamilyMinistry1 from '../../../asserts/images/OurMinistries/FF_1.png';
import FamilyMinistry2 from '../../../asserts/images/OurMinistries/FF_2.png';
import FamilyMinistry3 from '../../../asserts/images/OurMinistries/FF_3.png';

const FamilyMinistries = () => {

    const options = {
        items: 3,
        nav: true,
        rewind: true,
        autoplay: true,
        autoplayHoverPause: true,
        slideBy: 1,
        dots: false,
        loop: true,
        // arrows: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            576: {
                items: 1
            },
            992: {
                items: 3

            }

        }

    }


    return (
        <Aux>
            <section className="my-5 spx-2" id="section1">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <div className="d-inline-block">
                                    <div className="family-ministries-title">FAMILY MINISTRIES</div>
                                    <img className="title-underline-img" src={Underline} alt="underline" />
                                    <p className="family-ministries-subtitle mt-3">
                                        And let us consider one another in order to stir up love and good works,
                                        not forsaking the assembling of ourselves together, as is the manner of some,
                                        but exhorting one another,
                                        and so much the more as you see the Day approaching - Hebrews 10:24-25
                                </p>
                                </div>
                            </div>

                            <p className="family-ministries-details">
                                The bible says in the book of Acts chapter two that the early Christians
                                met in each other’s houses and praised the lord and the Lord added to the
                                church those who are being saved. Based on the above verses and led by God
                                the Holy Spirit we started the 5G ministry in our church. The church was
                                divided into various areas and further was divided into a different group
                                of 5 families, each group was given a leader. The 5G leaders were given the
                                responsibility to conduct weekly house meetings and the believers were
                                encouraged to bring in their non-Christian neighbours with them to attend
                                these meetings. The 5G leaders are also entrusted with the responsibility
                                of meeting the new comers and to pray for them. On the last Sunday of every
                                month I meet with all the 5G leaders and encourage them with the word of God.
                                They also give me a report of all the great things the Lord has done
                                in their ministry.
                            </p>
                        </div>
                    </div>

                    <div className="row ministries-small-hidden">                        
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 family-ministry-img">
                            <img src={FamilyMinistry1} alt="Family Meeting" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 family-ministry-img">
                            <img src={FamilyMinistry2} alt="Family Meeting" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 family-ministry-img">
                            <img src={FamilyMinistry3} alt="Family Meeting" />
                        </div>
                    </div>
                </div>
            </section>

             {/* Slider mode in small devices */}

             <section className="spx-2">
                <div className='container-fluid outreach-slider' >

                    <OwlCarousel className="owl-theme ministry-slider-card" margin={8} {...options}>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 family-ministry-img">
                            <img src={FamilyMinistry1} alt="Family Meeting" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 family-ministry-img">
                            <img src={FamilyMinistry2} alt="Family Meeting" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 family-ministry-img">
                            <img src={FamilyMinistry3} alt="Family Meeting" />
                        </div>
                    </OwlCarousel>
                </div>
            </section>
        </Aux>

    )
}

export default FamilyMinistries;