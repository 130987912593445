import React from 'react';
import Blief from '../../../asserts/images/HomePage/belief.svg';
import '../OurBelief/OurBelief.css'

const BliefHome = () => {

    return(
<section className="our-belief-section my-5">
                    <div className="container-fluid">
                        <div className="row px-5">
                            <div className="col-3 col-xl-2 mt-5">
                                <img className="img-responsive" src={Blief} alt="Pastors Belief"/>
                            </div>
                            <div className="col-9 col-xl-10 mt-5">
                                <div className="belief-header">OUR BELIEFS</div>
                                <div className="belief-sub-header mt-3">We believe in God the Father,
                                God the Son-Jesus Christ and God the Holy Spirit, three in One. </div>
                            </div>
                            <div className="col-12">
                                <div className="belief-detail mt-4">
                                    <p>God in His love sent His one and only Son Jesus Christ to
                                    redeem this world by paying the full penalty for its sins.
                                    Jesus died on the cross for each one of our sins.
                                    He rose again from the dead, ascended to heaven,
                                    promised to return and sent His Holy Spirit to be with us.
                                    Anyone who believes in the Lord Jesus Christ and repents of
                                    their sins receives forgiveness and eternal life,
                                    irrespective of tribe, tongue, religion or nationality.
                                </p>
                                    <p>All who believe in Jesus will externalize their commitment to God by obeying Him
                                    in the waters of baptism, thus being initiated as disciples of Christ into
                                    His family, the Church. The Church of Jesus Christ has been called to have a
                                    dynamic relationship with the Holy Spirit, to learn, live out and teach the
                                    inspired timeless, trans-cultural truths of the Word of God.
                                    We believe that all matters concerning the life and beliefs of the
                                    churches are to be settled by the Word of God. Though the churches
                                    are to be guarded and protected by gifted leaders, all are subject
                                    to the authority of the biblical text.
                                </p>
                                    <p>The glorious return of our Lord Jesus Christ for His Church is coming soon.
                                    He will judge and reward, the living and the dead and will establish His
                                    kingdom forever. Until Jesus comes again the Church is called to be in the world,
                                    yet not of the world; submit to all authority that God has placed;
                                    be prayerful and watchful and be the salt and light to the world.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    )
}

export default BliefHome;