import React from 'react';
import { NavLink } from 'react-router-dom';
import '../MediaMinistry/MediaMinistry.css';
import Underline from '../../../asserts/images/header_line.svg';

const MediaHome = () => {

    return (
        <section>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="ministry-header"> MEDIA MINISTRIES</div>
                        <img className="d-inline-block mb-5 media-mini-img" src={Underline} alt="underline" />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 media-ministry audio-songs text-center">
                        <div className="w-100 media-contain d-inline-block">
                            <div className="media-header">
                                AUDIO SONGS
                                    </div>
                            <button className="btn btn-custom mt-5">
                                <NavLink to="/Media" exact className="media-link">Click Here</NavLink>
                            </button>
                        </div>
                        <div className="overlay-effect-1 h-100 w-100"></div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 media-ministry video-songs text-center">
                        <div className="w-100 media-contain d-inline-block">
                            <div className="media-header">
                                VIDEO SONGS
                                    </div>
                            <button className="btn btn-custom mt-5">
                                <NavLink to="/Media" exact className="media-link">Click Here</NavLink>
                            </button>
                        </div>
                        <div className="overlay-effect-2 h-100 w-100"></div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 media-ministry video-sermon text-center">
                        <div className="w-100 media-contain d-inline-block">
                            <div className="media-header">
                                VIDEO SERMONS
                                    </div>
                            <button className="btn btn-custom mt-5">
                                <NavLink to="/Media" exact className="media-link">Click Here</NavLink>
                            </button>
                        </div>
                        <div className="overlay-effect-3 h-100 w-100"></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MediaHome;