import React from 'react';
import DioceseLogo from '../../asserts/images/diocese_logo.svg';
import Underline from '../../asserts/images/header_line.svg';
import '../Layout/Layout.css'

const Footer = () => {

    return (

        <footer className="footer-contain">
            <section>
                <div className="container-fluid margin-space">
                    <div className="row mt-4">
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 footer-img-contain">
                            <img src={DioceseLogo} alt="Diocese Logo" className="img-responsive" />
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-10 col-sm-12 col-xs-12">

                            <div className="col-12 text-center">
                                <div className="about-church-header "> ABOUT OUR CHURCH</div>
                                <img className="d-inline-block mb-5 title-underline-img" src={Underline} alt="underline" />
                            </div>
                            <p className="church-details">Jesus with Us Church, Tondairpet is formed a diocese with the count
                            of 32 churches in and around Chennai area and affiliated with CEEC
                            (Continuing Evangelical Episcopal Communion). CEEC is a communion
                            of the one, holy catholic and apostolic Church created by a
                            convergence of the three great historical expressions of faith and
                            practice: the Evangelical/Biblical, the Charismatic/Pentecostal,
                                and the Liturgical/Sacramental traditions.</p>
                        </div>

                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                            <div className="col-12 text-center">
                                <div className="about-church-header "> LOCATION</div>
                                <img className="d-inline-block mb-5 title-underline-img" src={Underline} alt="underline" />
                            </div>
                            <div className="map-contain">
                                <iframe title="Jesus with us tondiarpet" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.5665133057723!2d80.28398931430576!3d13.12662521501043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526f968d570e43%3A0xf34befb58dcb54e5!2sJesus%20With%20Us%20Church!5e0!3m2!1sen!2sin!4v1590656529590!5m2!1sen!2sin" className="footer-map" frameBorder="0" allowFullScreen="" tabIndex="0"></iframe>
                            </div>

                        </div>

                        <div className="col-12 text-center mt-5 mb-3">
                            <div className="footer-note ">
                                Copyright &copy;2020 - Jesus With Us Church. All Right Reserved
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )

}

export default Footer;