import React, { Component } from 'react';
import Slider from "react-slick";
import Aux from '../../../hoc/Aux';
import '../../../api/Youtube.css';
import './Sermons.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const newJwucAPI = 'AIzaSyAjLqRMmsQEFjy1UpOzCKlLx6MQnqHIrws';
const jwucID = 'UCHueX5dB4yGj6Z5ZQOYi3yA'
const result = 10;
var sermonUrl = `https://www.googleapis.com/youtube/v3/search?key=${newJwucAPI}&view=2&channelId=${jwucID}&part=snippet,id&order=date&maxResults=${result}`
var allVideosUrl = `https://www.googleapis.com/youtube/v3/activities?part=snippet%2CcontentDetails&channelId=UCHueX5dB4yGj6Z5ZQOYi3yA&maxResults=25&key=${newJwucAPI}`
class Sermon extends Component {

    constructor(props) {
        super(props)
        this.state = {
            embedSrc: "",
            playlistItem: [],
            playlistTitle: [],
            videoSrc:"",
            playlistItems:[]
        }
    }

    componentDidMount() {

        fetch(sermonUrl)
            .then(response => response.json())
            .then(res => {
                const playlistItem = res.items.map(obj => obj)
                console.log(playlistItem, "embed Source code")
                const embedUrl = playlistItem.map(listObj => listObj.id.videoId)
                const embedSrc = `https://www.youtube.com/embed/${embedUrl[0]}?rel=0`
                const playlistTitleArray = playlistItem.map(items => items.snippet.title)
                const playlistTitle = playlistTitleArray[0];
                // console.log(playlistItem, "embed Source code")
                this.setState({ playlistItem, embedSrc, playlistTitle })

            })



            
        // fetch(allVideosUrl)
        // .then(response => response.json())
        // .then(res => {
        //     const playlistItems = res.items.map(obj => obj)
        //     const videoUrl = playlistItems.map(obj=>obj.contentDetails.upload.videoId)
        //     const videoSrc = `https://www.youtube.com/embed/${videoUrl[0]}?rel=0`
        //     console.log(videoSrc, "embed Source code activities")
            
        //     this.setState({ playlistItems, videoSrc })
        // })
    }



    onClickVideo(videoUrl, playlistTitle) {

        this.setState({
            embedSrc: "https://www.youtube.com/embed/" + videoUrl+"?rel=0",
            playlistTitle: playlistTitle
        })   

    }

    // onClickVideos(videoUrl) {

    //     this.setState({
    //         videoSrc: "https://www.youtube.com/embed/" + videoUrl+"?rel=0",
    //         // playlistTitle: playlistTitle
    //     })   

    // }

    render() {
        console.log(allVideosUrl, "JWUC Sermons Video List")
        const sliderSettings = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            className: "slides",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <Aux>
                <div className="container-fluid my-4">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12" >
                            <div className="youtube-video-player">
                                <iframe src={this.state.embedSrc} width="100%" title="Sermons from JWUC" height="400px"
                                    frameBorder="0" allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </div>
                            <div>
                                <p className="each-video-title">{this.state.playlistTitle}</p>
                            </div>
                        </div>
                    </div>
                </div>

                
                    <div className="spx-sm">
                        <Slider {...sliderSettings}>
                            {
                                this.state.playlistItem.map((items, i) => {
                                    let playlistItem = <div className="slider-outer-div" key={i} >
                                        <div className="thumbnail-card">
                                            <img src={items.snippet.thumbnails.high.url} className="img-responsive" onClick={this.onClickVideo.bind(this, items.id.videoId, items.snippet.title)} alt="Thumbnails" />
                                            <div className="thumbnail-card-body">
                                                <p className="thumbnail-card-text">{items.snippet.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                    // console.log(items.snippet, "Checking of api calls")
                                    return playlistItem;
                                })
                            }
                        </Slider>
                    </div>


                    {/* <div className="container-fluid my-4">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12" >
                            <div className="youtube-video-player">
                                <iframe src={this.state.videoSrc} width="100%" title="Sermons from JWUC" height="400px"
                                    frameBorder="0" allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </div>
                             <div>
                                <p className="each-video-title">{this.state.playlistTitle}</p>
                            </div> 
                        </div>
                    </div>
                </div>

                <div className="spx-5">
                        <Slider {...sliderSettings}>
                            {
                                this.state.playlistItems.map((items, i) => {
                                    let playlistItem = <div className="slider-outer-div" key={i} >
                                        <div className="thumbnail-card">
                                            <img src={items.snippet.thumbnails.high.url} className="img-responsive" onClick={this.onClickVideos.bind(this, items.contentDetails.upload.videoId)} alt="Thumbnails" />
                                            <div className="thumbnail-card-body">
                                                <p className="thumbnail-card-text">{items.snippet.title}</p>
                                            </div>
                                        </div>
                                    </div>
                                     console.log(items.contentDetails.upload.videoId, "Checking of api calls")
                                    return playlistItem;
                                })
                            }
                        </Slider>
                    </div> */}

            </Aux>

        )
    }
}
    

export default Sermon;