import React from 'react';
import Aux from '../../../hoc/Aux';
import '../../../responsive.css';
import '../../../containers/Ministries/Ministries.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Outreach/Outreach.css';
import Underline from '../../../asserts/images/header_line.svg';
import Outreach1 from '../../../asserts/images/OurMinistries/2017_1.png';
import Outreach2 from '../../../asserts/images/OurMinistries/2017_2.png';
import Outreach3 from '../../../asserts/images/OurMinistries/2017_3.png';
import Outreach4 from '../../../asserts/images/OurMinistries/2018_1.png';
import Outreach5 from '../../../asserts/images/OurMinistries/2018_2.png';
import Outreach6 from '../../../asserts/images/OurMinistries/2018_3.png';
import Outreach7 from '../../../asserts/images/OurMinistries/2019_1.png';
import Outreach8 from '../../../asserts/images/OurMinistries/2019_2.png';
import Outreach9 from '../../../asserts/images/OurMinistries/2019_3.png';

const OutreachMinistries = () => {

    const options = {
        items: 3,
        nav: true,
        rewind: true,
        autoplay: true,
        autoplayHoverPause: true,
        slideBy: 1,
        dots: false,
        loop: true,
        // arrows: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            576: {
                items: 1
            },
            992: {
                items: 3

            }

        }

    }

    return (

        <Aux>
            <section className="spx-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <div className="d-inline-block">
                                    <div className="outreach-title">GOSPEL OUTREACH MINISTRIES</div>
                                    <img className="title-underline-img" src={Underline} alt="underline" />
                                    <p className="outreach-subtitle mt-3">
                                        And He said to them, "Go into all the world and preach the gospel to every creature" - Mark 15:16
                                </p>
                                </div>
                            </div>

                            <p className="outreach-details">
                                Every year (usually in the month of May) we visit a village and conduct
                                a special three-day meeting in that area. We also visit the villagers in the
                                daytime and proclaim the gospel in the houses and also pray for them.
                                The main purpose of the Village ministry is to proclaim the gospel and
                                establish churches in places where there are no churches
                                and to help the churches in places where there are already churches.
                            </p>
                        </div>
                    </div>

                        <div className="ministries-small-hidden">
                            <div className="row">        
                                <div className="col-12 nannilam-outreach-title mb-4">
                                    <b>Year</b> - 2017  <b className="ml-3">Location</b> - Nannilam,
                                    Tiruvarur District, South Tamilnadu
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach1} alt="Nannilam 2017 Outreach" />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach2} alt="Nannilam 2017 Outreach" />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach3} alt="Nannilam 2017 Outreach" />
                                </div>
                            </div>

                            <div className="row my-5">
                                <div className="col-12 nannilam-outreach-title mb-4">
                                        <b>Year</b> - 2018  <b className="ml-3">Location</b> - Nannilam,
                                    Tiruvarur District, South Tamilnadu
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach4} alt="Nannilam 2018 Outreach" />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach5} alt="Nannilam 2018 Outreach" />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach6} alt="Nannilam 2018 Outreach" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 nannilam-outreach-title mb-4">
                                    <strong>Year</strong> - 2019  <b className="ml-3">Location</b> - Nannilam,
                                Tiruvarur District, South Tamilnadu
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach7} alt="Nannilam 2019 Outreach" />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach8} alt="Nannilam 2019 Outreach" />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                                    <img className="img-responsive" src={Outreach9} alt="Nannilam 2019 Outreach" />
                                </div>
                            </div>
                        </div>
                    
                </div>
            </section>

            {/* Slider mode in small devices */}

            <section className="spx-2">
                <div className='container-fluid outreach-slider' >

                    <OwlCarousel className="owl-theme ministry-slider-card outreach-ministry-slider" margin={8} {...options}>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                            <div className="col-12 nannilam-outreach-title mb-4">
                                <b>Year</b> - 2017  <b className="ml-3">Location</b> - Nannilam,
                                Tiruvarur District, South Tamilnadu
                            </div>
                            <img className="img-responsive" src={Outreach1} alt="Nannilam 2017 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                            <div className="col-12 nannilam-outreach-title mb-4">
                                <b>Year</b> - 2017  <b className="ml-3">Location</b> - Nannilam,
                                Tiruvarur District, South Tamilnadu
                            </div>
                            <img className="img-responsive" src={Outreach2} alt="Nannilam 2017 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                            <div className="col-12 nannilam-outreach-title mb-4">
                                <b>Year</b> - 2017  <b className="ml-3">Location</b> - Nannilam,
                                Tiruvarur District, South Tamilnadu
                            </div>

                            <img className="img-responsive" src={Outreach3} alt="Nannilam 2017 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                        <div className="col-12 nannilam-outreach-title mb-4">
                                <b>Year</b> - 2018  <b className="ml-3">Location</b> - Nannilam,
                            Tiruvarur District, South Tamilnadu
                        </div>
                            <img className="img-responsive" src={Outreach4} alt="Nannilam 2018 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                        <div className="col-12 nannilam-outreach-title mb-4">
                                <b>Year</b> - 2018  <b className="ml-3">Location</b> - Nannilam,
                            Tiruvarur District, South Tamilnadu
                        </div>
                            <img className="img-responsive" src={Outreach5} alt="Nannilam 2018 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                        <div className="col-12 nannilam-outreach-title mb-4">
                                <b>Year</b> - 2018  <b className="ml-3">Location</b> - Nannilam,
                            Tiruvarur District, South Tamilnadu
                        </div>
                            <img className="img-responsive" src={Outreach6} alt="Nannilam 2018 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                        <div className="col-12 nannilam-outreach-title mb-4">
                                <strong>Year</strong> - 2019  <b className="ml-3">Location</b> - Nannilam,
                                Tiruvarur District, South Tamilnadu
                            </div>
                            <img className="img-responsive" src={Outreach7} alt="Nannilam 2019 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                        <div className="col-12 nannilam-outreach-title mb-4">
                                <strong>Year</strong> - 2019  <b className="ml-3">Location</b> - Nannilam,
                                Tiruvarur District, South Tamilnadu
                            </div>
                            <img className="img-responsive" src={Outreach8} alt="Nannilam 2019 Outreach" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 nannilam-img">
                        <div className="col-12 nannilam-outreach-title mb-4">
                                <strong>Year</strong> - 2019  <b className="ml-3">Location</b> - Nannilam,
                                Tiruvarur District, South Tamilnadu
                            </div>
                            <img className="img-responsive" src={Outreach9} alt="Nannilam 2019 Outreach" />
                        </div>

                    </OwlCarousel>
                </div>
            </section>
        </Aux>

    )
}

export default OutreachMinistries;