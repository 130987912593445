import React, { Component } from 'react';
import Aux from '../../hoc/Aux';
import OutreachMinistries from '../../components/MinistriesSubComponents/Outreach/OutreachMinistries';
import ChildrenMinistries from '../../components/MinistriesSubComponents/Children/ChildrenMinistries';
import YouthMinistries from '../../components/MinistriesSubComponents/Youth/YouthMinistries';
import FamilyMinistries from '../../components/MinistriesSubComponents/Family/FamilyMinistries';
import Footer from '../../components/Layout/Footer';
import youthIcon from '../../asserts/images/OurMinistries/youth_icon.svg'

class Minstries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            outreachMinistries: true,
            childrenMinistries: false,
            youthMinistries: false,
            familyMinistries: false,
            isOutreachActive: true,
            isChildrenActive: false,
            isYouthActive: false,
            isFamilyActive: false
        };
        this.outreachMinistriesMethod = this.outreachMinistriesMethod.bind(this);
        this.childrenMinistriesMethod = this.childrenMinistriesMethod.bind(this);
        this.youthMinistriesMethod = this.youthMinistriesMethod.bind(this);
        this.familyMinistriesMethod = this.familyMinistriesMethod.bind(this);
    }

    outreachMinistriesMethod() {
        this.setState({
            outreachMinistries: true,
            childrenMinistries: false,
            youthMinistries: false,
            familyMinistries: false,
            isOutreachActive: true,
            isChildrenActive: false,
            isYouthActive: false,
            isFamilyActive: false
            


        });

    }

    childrenMinistriesMethod() {
        this.setState({
            outreachMinistries: false,
            childrenMinistries: true,
            youthMinistries: false,
            familyMinistries: false,
            isOutreachActive: false,
            isChildrenActive: true,
            isYouthActive: false,
            isFamilyActive: false
        });
    }

    youthMinistriesMethod() {
        this.setState({
            outreachMinistries: false,
            childrenMinistries: false,
            youthMinistries: true,
            familyMinistries: false,
            isOutreachActive: false,
            isChildrenActive: false,
            isYouthActive: true,
            isFamilyActive: false
        });
    }

    familyMinistriesMethod() {
        this.setState({
            outreachMinistries: false,
            childrenMinistries: false,
            youthMinistries: false,
            familyMinistries: true,
            isOutreachActive: false,
            isChildrenActive: false,
            isYouthActive: false,
            isFamilyActive: true
        });
    }

    render() {
        const {isOutreachActive, isChildrenActive, isYouthActive, isFamilyActive } = this.state;
        return (
            <Aux>
                
                <section className="m-custom">
                    <header className="header-ministry">
                        <div className="col-12 ministry-tag">
                            <div className="ministry-tag-text">
                                <p className="p-2">OUR MINISTRIES</p>
                            </div>
                        </div>
                    </header>
                </section>
                <div className="ministries-hide-small">
                <OutreachMinistries />
                <ChildrenMinistries />
                <YouthMinistries />
                <FamilyMinistries />
                </div>
                

                <section className="ministry-section-show-sm">
                    <div className="container-fluid">
                        <div className="row justify-content-center my-5">
                            <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-xs-12 text-center">
                                <div className="media-icons-sm">
                                    <span  onClick={this.outreachMinistriesMethod} className={`icon ${isOutreachActive ? 'icon-outreach-active ' : 'icon-outreach'}`} ></span>
                                    <span  onClick={this.childrenMinistriesMethod} className={`icon ${isChildrenActive ? 'icon-children-active ' : 'icon-children'}`} ></span>
                                    <span  onClick={this.youthMinistriesMethod} className={`icon ${isYouthActive ? 'icon-youth-active ' : 'icon-youth'}`} ></span>
                                    <span  onClick={this.familyMinistriesMethod} className={`icon ${isFamilyActive ? 'icon-family-active ' : 'icon-family'}`} ></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.outreachMinistries ? <OutreachMinistries /> : null}
                    {this.state.childrenMinistries ? <ChildrenMinistries /> : null}
                    {this.state.youthMinistries ? <YouthMinistries /> : null}
                    {this.state.familyMinistries ? <FamilyMinistries /> : null}
                </section>
                <Footer />

            </Aux>

        )
    }
}

export default Minstries;