import React, { Component } from 'react';
import Aux from '../../hoc/Aux';
import '../../responsive.css';
import AboutPastor from '../../components/AboutSubComponents/AboutPastor/AboutPastor';
import Footer from '../../components/Layout/Footer';
import PastorsTestimony from '../../components/AboutSubComponents/PastorTestimony/PastorTestimony';

class AboutUs extends Component {

    // state = {

    //     mainBg : {
    //         background: 'url("../../asserts/images/about_banner.svg")',
    //         backgroundRepeat: 'no-repeat',
    //         backgroundSize: 'cover',
    //         backgroundPosition: 'center',
    //       }
    // }

    // componentWillMount() {
    //     document.body.style = {...this.state.mainBg};
    //   }


    render() {

        return (
            <Aux>
                <section className="m-custom">
                    <header className="header-about">
                        <div className="col-12 about-tag">
                            <div className="about-tag-text">
                                <p className="p-2">ABOUT US</p>
                            </div>
                        </div>
                    </header>
                </section>
                <AboutPastor />
                <PastorsTestimony />
                <Footer />

            </Aux>

        )
    }
}

export default AboutUs;