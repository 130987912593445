import React, { Component } from 'react'
import '../AudioSongs/AudioSongs.css'
import '../../../responsive.css'
import AGVol1 from '../../../asserts/images/Media/AG_Vol1.png'
import AGVol2 from '../../../asserts/images/Media/AG_Vol2.png'
import UyireVol1 from '../../../asserts/images/Media/UYIRE_vol1.png'
import UyireVol2 from '../../../asserts/images/Media/UYIRE_vol2.png'
import UyireVol3 from '../../../asserts/images/Media/UYIRE_vol3.png'
import Playbtn from '../../../asserts/images/Media/play.png'
import Pausebtn from '../../../asserts/images/Media/pause.png'

// import axios from 'axios'
// import SongList from '../../Data/data.json'

class AudioSongs extends Component {

  constructor(props) {
    super(props)
    this.startPlayer = this.startPlayer.bind(this);

    this.state = {
      selectedTrack: null,
      selectedTrackId: null,
      player: "stopped",
      isLoggedIn:true
    };

  }

   
    
    // state = {
    //     persons: []
    // }

    // componentDidMount() {
    //     axios.get(`data.json`)
    //         .then(res => {
    //             console.log(res);
    //             this.setState({
    //                 persons: res.data
    //             })
    //         })

    // }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.selectedTrack !== prevState.selectedTrack) {
          let track;
          switch(this.state.selectedTrack) {
            case "Unnathathil Vasam Seiyum":
              track = "https://archive.org/download/02-unnathathil-vasam-seiyum/02%20Unnathathil%20Vasam%20Seiyum.mp3"
            break;
            case "Thiriyega Thevane":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/03%20Thiriyega%20Thevane.mp3"
              break;
              case "Thuthiyinil Magilbavare":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/04%20Thuthiyil%20Magilbavare.mp3"
              break;
              case "Ummai Marava":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/05%20Ummai%20Marava.mp3"
              break;
              case "Unga Veettu Raja":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/06%20Unga%20Veettu%20Raja.mp3"
              break;
              case "Sothirame Paathirare":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/07%20Sothirame%20Paathirare.mp3"
              break;
              case "Thuthigeetham Paaduvom":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/08%20Thuthigeetham%20Paaduvom.mp3"
              break;
              case "Thuyavar Thuyavar":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/09%20Thuyavar%20Thuyavar.mp3"
              break;
              case "Udaikapatta Ullame":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/10%20Udaikapatta%20Ullame.mp3"
              break;
              case "Kaalaithorum Kirubai":
                track = "https://archive.org/download/02-unnathathil-vasam-seiyum/11%20Kaalaithorum%20Kirubai.mp3"
              break;
                // arathanai geethangal 2
                case "Jeeva Baliyaga":
                  track = "https://archive.org/download/aradhanai-geethangal-2/01%20Jeeva%20Baliyaga.mp3"
                break;
                case "Magimaiyin Thevane":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Magimaiyin%20Thevane.mp3"
                  break;
                  case "Namma Ooru":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Namma%20Ooru.mp3"
                  break;
                  case "Rasave neer yeppothum":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Rasave%20neer%20yeppothum.mp3"
                  break;
                  case "Thothane Thothane":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Thothane%20Thothane.mp3"
                  break;
                  case "Ummai Thuthipathum":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Ummai%20Thuthipathum.mp3"
                  break;
                  case "Unnathare":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Unnathare.mp3"
                  break;
                  case "Vaanam Umathu":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Vaanam%20Umathu.mp3"
                  break;
                  case "Yenakul Iruppavar":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01%20Yenakul%20Iruppavar.mp3"
                  break;
                  case "Ponguthe manam":
                    track = "https://archive.org/download/aradhanai-geethangal-2/01Ponguthe%20manam.mp3"
                  break;
                //   uyirae 1
                  case "Uyirae Uyirae":
                    track = "https://archive.org/download/uyirae/02%20Uyirae%20Uyirae.mp3"
                  break;
                  case "Vallavar Yesuvai":
                    track = "https://archive.org/download/uyirae/03%20Vallavar%20Yesuvai.mp3"
                  break;
                  case "Ummai Nambi":
                    track = "https://archive.org/download/uyirae/04%20Ummai%20Nambi.mp3"
                  break;
                  case "Naan Nesukiraen":
                    track = "https://archive.org/download/uyirae/05%20Naan%20Nesukiraen.mp3"
                  break;
                  case "Anbe Umakku":
                      track = "https://archive.org/download/uyirae/06%20Anbe%20Umakku.mp3"
                    break;
                    case "Urirulla Natkallellam":
                      track = "https://archive.org/download/uyirae/07%20Urirulla%20Natkallellam.mp3"
                    break;
                    case "Enn Maenmaiyum":
                      track = "https://archive.org/download/uyirae/09%20Enn%20Maenmaiyum.mp3"
                    break;
                    case "Neer Illa Nallellam":
                      track = "https://archive.org/download/uyirae/11%20Neer%20Illa%20Nallellam.mp3"
                    break;
                    // Uyirea -2 
                    case "Athisayangal Seibavare":
                      track = "https://archive.org/download/uyirae-2/Athisayangal%20Seibavare.mp3"
                    break;
                    case "Devane Yum Samugame":
                      track = "https://archive.org/download/uyirae-2/Devane%20Yum%20Samugame.mp3"
                    break;
                    case "Halleluya Katharai":
                      track = "https://archive.org/download/uyirae-2/Halleluya%20Katharai.mp3"
                    break;
                    case "Nandri Solli paduven":
                      track = "https://archive.org/download/uyirae-2/Nandri%20Solli%20paduven.mp3"
                    break;
                    case "Neer Podhume":
                      track = "https://archive.org/download/uyirae-2/Neer%20Podhume.mp3"
                    break;
                    case "Neerthanaiah yen nesar":
                      track = "https://archive.org/download/uyirae-2/Neerthanaiah%20yen%20nesar.mp3"
                    break;
                    case "Pudhuragam Vanthathu":
                      track = "https://archive.org/download/uyirae-2/Pudhuragam%20Vanthathu.mp3"
                    break;
                    case "Uyire Uyire":
                      track = "https://archive.org/download/uyirae-2/Uyire%20Uyire.mp3"
                    break;
                    case "Yegova yeere":
                        track = "https://archive.org/download/uyirae-2/Yegova%20yeere.mp3"
                      break;

                //   uyirae - 3
                  case "Aathiyum Anthamum":
                    track = "https://archive.org/download/uyirae-3/Aathiyum%20Anthamum.mp3"
                  break;
                  case "Belanaagiya Karthaave":
                    track = "https://archive.org/download/uyirae-3/Belanaagiya%20Karthaave.mp3"
                  break;
                  case "Kurusiyil Thongiya":
                    track = "https://archive.org/download/uyirae-3/Kurusiyil%20Thongiya.mp3"
                  break;
                  case "Thetraravalan Thunaiyai":
                    track = "https://archive.org/download/uyirae-3/Thetraravalan%20Thunaiyai.mp3"
                  break;
                  case "Um Naamam Uyaranume":
                    track = "https://archive.org/download/uyirae-3/Um%20Naamam%20Uyaranume.mp3"
                  break;
                  case "Vallamaimela Vallamai":
                    track = "https://archive.org/download/uyirae-3/Vallamaimela%20Vallamai.mp3"
                  break;
                  case "Yen Maipare":
                    track = "https://archive.org/download/uyirae-3/Yen%20Maipare.mp3"
                  break;
                  case "Yoshuvin Santhathi":
                    track = "https://archive.org/download/uyirae-3/Yoshuvin%20Santhathi.mp3"
                  break;
            default:
            break;
          }
          if(track) {
            this.player.src = track;
            this.player.play()
          }
        }


        // if (this.state.player !== prevState.player) {
        //     if (this.state.player === "paused") {
        //       this.player.pause();
        //     } else if (this.state.player === "stopped") {
        //       this.player.pause();
        //       this.setState({ selectedTrack: null });
        //     } else if (
        //       this.state.player === "playing" &&
        //       prevState.player === "paused"
        //     ) {
        //       this.player.play();
        //     }
        //   }
      }

      
      startPlayer = (item, id) => {
        this.setState({ selectedTrack: item, selectedTrackId: id, isLoggedIn:false})        
        console.log("ID of songs", this.state.isLoggedIn)       
          this.player.play();
       

      }

      stopPlayer = (i) => {
        this.setState({ isLoggedIn:true, selectedTrackId: null  });
        this.player.pause();
      }
  

    render() {     
      
      const isLoggedIn = this.state.isLoggedIn;
        const SongsArray = [
            {id: 1, title: "Unnathathil Vasam Seiyum" }, 
            {id: 2, title: "Thiriyega Thevane"},
            {id: 3, title: "Thuthiyinil Magilbavare"},
            {id: 4, title: "Ummai Marava"},
            {id: 5, title: "Unga Veettu Raja"},
            {id: 6, title: "Sothirame Paathirare"},
            {id: 7, title: "Thuthigeetham Paaduvom"},
            {id: 8, title: "Thuyavar Thuyavar"},
            {id: 9, title: "Udaikapatta Ullame"},
            {id: 10, title: "Kaalaithorum Kirubai"},
            {id: 11, title: "Jeeva Baliyaga" }, 
            {id: 12, title: "Magimaiyin Thevane"},
            {id: 13, title: "Namma Ooru"},
            {id: 14, title: "Rasave neer yeppothum"},
            {id: 15, title: "Thothane Thothane"},
            {id: 16, title: "Ummai Thuthipathum"},
            {id: 17, title: "Unnathare"},
            {id: 18, title: "Vaanam Umathu"},
            {id: 19, title: "Yenakul Iruppavar"},
            {id: 20, title: "Ponguthe manam"},
            {id: 21, title: "Uyirae Uyirae" }, 
            {id: 22, title: "Vallavar Yesuvai"},
            {id: 23, title: "Ummai Nambi"},
            {id: 24, title: "Naan Nesukiraen"},
            {id: 25, title: "Anbe Umakku"},
            {id: 26, title: "Urirulla Natkallellam"},
            {id: 27, title: "Enn Maenmaiyum"},
            {id: 28, title: "Neer Illa Nallellam"},
            {id: 31, title: "Athisayangal Seibavare" }, 
            {id: 32, title: "Devane Yum Samugame"},
            {id: 33, title: "Halleluya Katharai"},
            {id: 34, title: "Nandri Solli paduven"},
            {id: 35, title: "Neer Podhume"},
            {id: 36, title: "Neerthanaiah yen nesar"},
            {id: 37, title: "Pudhuragam Vanthathu"},
            {id: 38, title: "Uyire Uyire"},
            {id: 39, title: "Yegova yeere"},
            {id: 41, title: "Aathiyum Anthamum"}, 
            {id: 42, title: "Belanaagiya Karthaave"},
            {id: 43, title: "Kurusiyil Thongiya"},
            {id: 44, title: "Thetraravalan Thunaiyai"},
            {id: 45, title: "Um Naamam Uyaranume"},
            {id: 46, title: "Vallamaimela Vallamai"},
            {id: 47, title: "Yen Maipare"},
            {id: 48, title: "Yoshuvin Santhathi"}
            ].map((item, index) => {
              return (
                <div key={item.id} className="play-list">
                  <div>
                  {item.title}
                  </div>                                                         
                   
                  <div>
                  <img onClick={() =>item.id != this.state.selectedTrackId ? this.startPlayer(item.title,item.id ) : this.stopPlayer(index)  } className="play-pause-btn" src={item.id != this.state.selectedTrackId ? Playbtn : Pausebtn} alt="play btn" />
                     
                  </div>                     
                </div>
                             
        )
          });

          const aradhanai1 = SongsArray.slice(0,10);
          const aradhanai2 = SongsArray.slice(10,20);
          const uyirae1 = SongsArray.slice(20,28);
          const uyirae2 = SongsArray.slice(28,37);
          const uyirae3 = SongsArray.slice(37,45); 

        return (
            <section className="mb-5">
                <div className="container-fluid">
                    <div className="row">

                  
                    

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 spx-3 my-4">
                            <div className="audio-header">ARADHANAIGEETHANGAL - VOLUME 1</div>                            
                            <div className="row py-4 audio-songs-lists">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <img src={AGVol1} alt="Aarathanaigeethangal Volume 1" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                {aradhanai1}
                                <audio ref={ref => this.player = ref} />                                       
                                </div>
                            </div>
                         </div>

                         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 spx-3 my-4">
                            <div className="audio-header">ARADHANAIGEETHANGAL - VOLUME 2</div>
                            <div className="row py-4 audio-songs-lists">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <img src={AGVol2} alt="Uyire Volume 1" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

                                        {aradhanai2}
                                    
                                                                                               
                                </div>
                            </div>
                         </div>

                         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 spx-3 my-4">
                            <div className="audio-header">UYIRAE - VOLUME 1</div>
                            <div className="row py-4 audio-songs-lists">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <img src={UyireVol1} alt="Uyire Volume 1" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    
                                        {uyirae1}
                                                                                                    
                                </div>
                            </div>
                         </div>

                         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 spx-3  my-4">
                            <div className="audio-header">UYIRAE - VOLUME 2</div>
                            <div className="row py-4 audio-songs-lists">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <img src={UyireVol2} alt="Uyire Volume 1" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                   
                                        {uyirae2}                                    
                                                                                                   
                                </div>
                            </div>
                         </div>

                         <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 spx-3 my-4">
                            <div className="audio-header">UYIRAE - VOLUME 3</div>
                            <div className="row py-4 audio-songs-lists">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <img src={UyireVol3} alt="Uyire Volume 1" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                   
                                        {uyirae3}
                                                                                                    
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </section>
        )
    }

}

export default AudioSongs;