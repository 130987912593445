import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import Aux from '../../../hoc/Aux';
import '../../../responsive.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../../containers/Ministries/Ministries.css';
import Underline from '../../../asserts/images/header_line.svg';
import YouthMinistry1 from '../../../asserts/images/OurMinistries/YM_1.png';
import YouthMinistry2 from '../../../asserts/images/OurMinistries/YM_2.png';
import YouthMinistry3 from '../../../asserts/images/OurMinistries/YM_3.png';

const YouthMinistries = () => {

    const options = {
        items: 3,
        nav: true,
        rewind: true,
        autoplay: true,
        autoplayHoverPause: true,
        slideBy: 1,
        dots: false,
        loop: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            576: {
                items: 1
            },
            992: {
                items: 3

            }

        }

    }

    return (
        <Aux>
            <section className="mt-5 spx-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <div className="d-inline-block">
                                    <div className="youth-ministries-title">YOUTH MINISTRIES</div>
                                    <img className="title-underline-img" src={Underline} alt="underline" />
                                    <p className="youth-ministries-subtitle mt-3">
                                        How can a young man cleanse his way? By taking heed according to Your word - Psalms.119:9
                                </p>
                                </div>
                            </div>

                            <p className="youth-ministries-details">
                                The main goal of the youth meeting at Jesus With Us, Tondiarpet is Holy
                                living and becoming true disciples of Christ.With this goal in mind youth
                                meetings are conducted every week at 4.30 pm every Sunday. Apart from this
                                we also have an annual youth conference that is conducted on Independence
                                Day (Aug. 15th). And these meetings are attended by the youth from all the
                                Jesus With Us Churches in Chennai. We also conduct a special
                                prayer for all the students who are going to appear for their public exams.
                            </p>
                        </div>
                        </div>

                        <div className="row ministries-small-hidden">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 youth-ministry-img">
                                <img src={YouthMinistry1} alt="Youth Meeting" />
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 youth-ministry-img">
                                <img src={YouthMinistry2} alt="Youth Meeting" />
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 youth-ministry-img">
                                <img src={YouthMinistry3} alt="Youth Meeting" />
                            </div>
                        </div>
                    </div>
            </section>

            {/* Slider mode in small devices */}

            <section className="spx-2">
                <div className='container-fluid outreach-slider' >

                    <OwlCarousel className="owl-theme ministry-slider-card " margin={8} {...options}>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 youth-ministry-img">
                            <img src={YouthMinistry1} alt="Youth Meeting" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 youth-ministry-img">
                            <img src={YouthMinistry2} alt="Youth Meeting" />
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 youth-ministry-img">
                            <img src={YouthMinistry3} alt="Youth Meeting" />
                        </div>

                    </OwlCarousel>
                </div>
            </section>
        </Aux>

    )
}

export default YouthMinistries;