import React, { Component } from 'react';
import Aux from '../../hoc/Aux';
import Footer from '../../components/Layout/Footer'
import '../Giving/Giving.css'
import Underline from '../../asserts/images/header_line.svg';
import Fish from '../../asserts/images/fish.svg';

class Giving extends Component {
    render() {
        return (
            <Aux>

                <section className="m-custom">
                    <header className="header-giving">
                        <div className="col-12 giving-tag">
                            <div className="giving-tag-text">
                                <p className="p-2">GIVING</p>
                            </div>
                        </div>
                    </header>
                </section>

                <section className="px-3">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 mb-5">
                                <div className="text-center">
                                    <div className="d-inline-block">
                                        <div className="giving-title">GIVING TO MINISTRY</div>
                                        <img className="title-underline-img" src={Underline} alt="underline" />
                                        <p className="giving-subtitle mt-3">
                                            Whoever sows sparingly will also reap sparingly,
                                            and whoever sows generously will also reap generously. Each of you should give what you have decided in your heart to give, not reluctantly or under compulsion,
                                            for God loves a cheerful giver - 2 Corinthians 9:6-8
                                    </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-5">
                                <div className="text-center">
                                    <div className="d-inline-block">
                                        <div className="bank-title">BANK ACCOUNT DETAILS</div>
                                        <img className="title-underline-img" src={Underline} alt="underline" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-3 px-5 ">
                                <div className="row bank-detail-card py-3">
                                    <div className="co-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
                                        <img className="" src={Fish} alt="Five fish two loaves" />
                                    </div>

                                    <div className="co-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">
                                        <div className="row">
                                            <div className="co-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 bank-label">
                                                Account Name
                                            </div>
                                            <div className="co-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 bank-details">
                                                MS. YESU NAMMODU UZHIYANGAL
                                            </div>

                                            <div className="co-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 bank-label">
                                                Account Number
                                            </div>
                                            <div className="co-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 bank-details">
                                                914010000957398
                                            </div>

                                            <div className="co-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 bank-label">
                                                IFSC CODE
                                            </div>
                                            <div className="co-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 bank-details">
                                                UTIB0001619
                                            </div>

                                            <div className="co-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 bank-label">
                                                MICR
                                            </div>
                                            <div className="co-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 bank-details">
                                                600211056
                                            </div>

                                            <div className="co-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 bank-label">
                                                Address
                                            </div>
                                            <div className="co-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 bank-details">
                                                DOOR NO. E11 AGASTHIYA APARTMENTS, TONDIARPET, CHENNAI, TAMILNADU, INDIA - PIN 600081
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-5 text-center">
                                <div className="d-inline-block">
                                    <p className="mail-detail mt-3 px-3">
                                        KINDLY SEND YOUR SCREEN SHOT OF YOUR PAYMENTS WITH DETAILS TO
                                            <a href="mailto:jesuswithustondiarpet1991@gmail.com"> jesuswithustondiarpet1991@gmail.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Aux>

        )
    }
}

export default Giving;