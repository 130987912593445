import React, { Component } from 'react';
import './Header.css';
import Aux from '../../hoc/Aux'
import PastorImage from '../../asserts/images/HomePage/Banner_Pastor.png'

class Header extends Component {

  // state = {

  //   menuItems: [
  //     {id:1, to: '/', menu: 'Home'},
  //     {id:2, to: '/About-us', menu: 'About Us'},
  //     {id:3, to: '/Ministries', menu: 'Ministries'},
  //     {id:4, to: '/Prayer-Request', menu: 'Prayer Request'},
  //     {id:5, to: '/Giving', menu: 'Giving'}
  //   ]
  // }  

  render() {

    // const eachMenu =  this.state.menuItems.map(menu => {
    //   console.log(menu)
    //   return(
    //     <Navigation key ={menu.id} to = {menu.to} menu = {menu.menu} />
    //   )

    // });

    return (
      <Aux>
        <section className="m-custom">
          <header className="header-main">
            <div className="home-banner-tamil">
              <div className="home-banner-tamil-align text-hide-sm">
                <div className="home-banner-header">இயேசு நம்மோடு சபை</div>
                <div className="home-banner-loc">சென்னை</div>
              </div>
              <div className="home-banner-div img-responsive">
                <img className="home-banner-img" src={PastorImage} alt="JWUC Pastor  " />
              </div>
              <div className="home-banner-tamil-align text-hide-lg">
                <div className="home-banner-header">இயேசு நம்மோடு சபை</div>
                <div className="home-banner-loc">சென்னை</div>
              </div>
            </div>

            <div className="home-tag">
                <div className="home-tag-text">
              </div>
            </div>
          </header>
        </section>
      </Aux>
    )
  }

}

export default Header;