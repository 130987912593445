import React, { Component } from 'react';
import '../Home/home.css'
import '../../responsive.css'
import Aux from '../../hoc/Aux'
import Underline from '../../asserts/images/header_line.svg'
import MinistrySlider from '../slider/MinistrySlider/MinistrySlider'
import MediaHome from '../../components/HomeSubComponents/MediaMinistry/MediaMinistry'
import BeliefHome from '../../components/HomeSubComponents/OurBelief/OurBelief'
import Footer from '../../components/Layout/Footer'
import Header from '../../components/navigation/Header'

import Axios from 'axios';
export const AppContext = React.createContext();
 


class Home extends Component {

    state = {
        livevideo: [],
        videotitle: []
    }
    componentDidMount() {
        Axios.get(`http://jesuswithusministries.com/api/livevideo.php`)
        //  Axios.get(`http://localhost/api/livevideo.php`)
          .then(res => {
            const livevideo = res.data.data.video;
            const videotitle = res.data.data.title;
            this.setState({ livevideo });
            this.setState({ videotitle });
          })
      }
    
    render() {
        const {livevideo} = this.state;
        const {videotitle} = this.state;
        return (
            <Aux>
                <Header/>
                <section className="mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 text-center live-service-parent">
                                <div className="d-inline-block">
                                    <div className="live-home">LIVE SERVICE</div>
                                    <div className="underline-decoration">
                                        <img src={Underline} alt="underline" /> 
                                    </div>
                                   
                                     <iframe title="Live Service" className="live-service-home mt-4" src={livevideo} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> 
                                    {/* <div className="live-service-home mt-4" dangerouslySetInnerHTML={{ __html: this.state.livevideo }} /> */}
                                        <div className="live-video-title">{videotitle}</div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                <div className="prayer-time text-center">PRAYER TIMINGS</div>
                                <div className="underline-decoration text-center">
                                    <img className="d-inline-block" src={Underline} alt="underline" />

                                </div>
                                <div className="mt-3 prayer-card">
                                    <div className="card mt-2 card-sun">
                                        <div className="card-body">
                                            <div className="card-title card-t-1">SUNDAY WORSHIP</div>
                                            <div className="first-service mt-4"><span>FIRST SERVICE</span><span className="mr-2">-</span>  05:30 AM TO 07:30 AM (IST)</div>
                                            <div className="second-service mt-4">SECOND SERVICE <span className="mr-2">-</span> 08:30 AM TO 10:30 AM (IST)</div>
                                        </div>
                                    </div>
                                    <div className="card mt-2 card-tue">
                                        <div className="card-body">
                                            <div className="card-title card-t-2">TUESDAY PRAYER</div>
                                            <div className="second-service mt-4">HEALING PRAYER <span className="mr-2">-</span> 06:00 PM TO 08:00 PM (IST)</div>
                                        </div>
                                    </div>
                                    <div className="card mt-2 card-fri">
                                        <div className="card-body">
                                            <div className="card-title card-t-3">FRIDAY PRAYER</div>
                                            <div className="second-service mt-4">FASTING PRAYER <span className="mr-2">-</span> 10:30 AM TO 01:00 PM (IST)</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                
                <BeliefHome />
                <MinistrySlider />
                <MediaHome />
                <Footer />
            </Aux>

        )
    }
}

export default Home;