import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aux from '../hoc/Aux';
import './Youtube.css'

const myAPI = 'AIzaSyAsSz8CP33ZFk9pK2naioGPqA1X-vKsM2s';
const jwucID = 'UCHueX5dB4yGj6Z5ZQOYi3yA';
const newJwucAPI = 'AIzaSyAjLqRMmsQEFjy1UpOzCKlLx6MQnqHIrws'

// Test API for vidoe category
const testUrl = `https://www.googleapis.com/youtube/v3/videoCategories?part=snippet&hl=es&regionCode=ES&key=${myAPI} `

// const liveStreamTest = `GET https://www.googleapis.com/youtube/v3/liveStreams?part=snippet%2Ccdn%2CcontentDetails%2Cstatus&id=IyVZGKr-oYU&maxResults=20&key=${myAPI}`
const liveStreamTest = `https://www.googleapis.com/youtube/v3/liveStreams?part=snippet%2Ccdn%2CcontentDetails%2Cstatus&id=87669044802-cprsik61h15b7fll1sgoi512ntb6mtfq.apps.googleusercontent.com&key=${newJwucAPI}`

// Test API for vidoe category

const videoSongsUrl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=20&playlistId=PL40p6BymY3WR_aXa-7AJfnalyLdh_Gqul&key=${newJwucAPI}`

class Youtube extends Component {

    constructor(props) {
        super(props)
        this.state = {
            embedSrc: "",
            playlistItem: [],
            playlistTitle: []
        }
    }


    componentWillMount() {

        fetch(videoSongsUrl)
            .then(response => response.json())
            .then(res => {
                const playlistItem = res.items.map(obj => obj)
                const embedUrl = playlistItem.map(listObj => listObj.snippet.resourceId.videoId)
                const embedSrc = `https://www.youtube.com/embed/${embedUrl[0]}?rel=0`
                const playlistTitleArray = playlistItem.map(items => items.snippet.title)
                const playlistTitle = playlistTitleArray[0];
                // console.log(playlistTitle[0], "embed Source code")
                this.setState({ playlistItem, embedSrc, playlistTitle })

            })
    }


    onClickVideo(videoUrl, playlistTitle) {
        this.setState({
            embedSrc: "https://www.youtube.com/embed/" + videoUrl + "?rel=0",
            playlistTitle: playlistTitle
        })
    }

    render() {

        // console.log("LiveStream Api", liveStreamTest)

        const sliderSettings = {
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            className: "slides",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        // console.log(testUrl, "test Api")
        return (
            <Aux>
                <div className="container-fluid my-4">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12" >
                            <div className="youtube-video-player">
                                <iframe src={this.state.embedSrc} width="100%" title="Video Song from JWUC" height="400px"
                                    frameBorder="0" allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </div>
                            <div>
                                <p className="each-video-title">{this.state.playlistTitle}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="spx-sm">
                    <Slider {...sliderSettings}>
                        {
                            this.state.playlistItem.map((items, i) => {
                                let playlistItem = <div className="slider-outer-div" key={i} >
                                    <div className="thumbnail-card">
                                        <img src={items.snippet.thumbnails.high.url} className="img-responsive" onClick={this.onClickVideo.bind(this, items.snippet.resourceId.videoId, items.snippet.title)} alt="Thumbnails" />
                                        <div className="thumbnail-card-body">
                                            <p className="thumbnail-card-text">{items.snippet.title}</p>
                                        </div>
                                    </div>
                                </div>
                                // console.log(items.snippet, "Checking of api calls")
                                return playlistItem;
                            })
                        }
                    </Slider>
                </div>
            </Aux>

        )
    }
}

export default Youtube;