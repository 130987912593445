import React from 'react';
import '../../../containers/AboutUs/AboutUs.css'
import PastorImage from '../../../asserts/images/AboutUs/Pastor.png';
import Underline from '../../../asserts/images/header_line.svg'

const AboutPastor = () => {
    return (
        <section className="spx-2">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="text-center">
                            <div className="d-inline-block">
                                <div className="about-pastor-title">ABOUT PASTOR C SWAMYDOSS</div>
                                <img className="title-underline-img" src={Underline} alt="underline" />
                                <p className="about-pastor-subtitle mt-3">
                                    As for me and my house, we will serve the lord Joshua 24:15
                                </p>
                            </div>
                        </div>

                        <p className="about-pastor-details">
                            I am Pastor C. Swamydoss, Regional Pastor for all Jesus With Us Churches
                            in Chennai. I have been in full time ministry for more than 25 years from
                            1991 and was ordained as a Pastor by Father S.J. Berchmans, Jebathottam
                            Fellowship on 25th Dec 1991. It is my passion to carry out the Great Commission
                            and make disciples and build the church. God has also placed in my heart to bring
                            ten thousand souls to Jesus. It is with this goal in mind that
                            I and all at the Jesus With Us Church (Tondiarpet) go about serving the Lord.
                        </p>
                        <p className="about-pastor-details">
                            Over the years the Lord has enabled me to train hundreds of leaders
                            within the church in the ministry and also the Lord has helped me to set
                            up four branch churches all of which have grown and are currently functioning
                            as separate churches in the Jesus With Us family. God has blessed me with a wife
                            S. Rosy and three wonderful children (Blessy, Rufus and Alice) who are also
                            serving the Lord alongside me in fulfilling His vision in varying capacities.
                        </p>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <img className="about-pastor-img img-responsive mt-2" src={PastorImage} alt="About Pastor" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutPastor;