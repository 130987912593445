import React from 'react'
import Youtube from '../../../api/Youtube'

const VideoSongs = () => {


        return (
            <Youtube />
        )
    }

export default VideoSongs;