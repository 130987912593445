import React from 'react';

const PastorsTestimony = () => {

    return (

        <section className="spx-2 mt-5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="pastor-testimony-title mb-2">
                            PASTOR'S TESTIMONY / MINISTRIES / FUTURE PLANS
                        </div>
                        <p className="pastor-testimony-details">
                            I was born and brought up in a Christian home. We went to church every Sunday,
                            celebrated Christmas and Easter every year. In spite of having a Christian
                            upbringing I was a nonbeliever. We had a big Bible at home but it was seldom
                            read it. It was like an old trophy on a pedestal. Church was something that we
                            did as a repeated task once a week. I grew up in extreme poverty and as a result
                            my education was cut short and I had to take up nominal jobs to earn a living
                            and support my family. I started spending my time with my friends and lived like
                            there was no tomorrow constantly thinking about politics and movies. Life was
                            going on and I was staring at a dark tomorrow and a bleak future. It was at this
                            time that I was led to accept Jesus as my personal saviour by friend in the year
                            1988. Even though I had accepted the Lord I continued to attend my local catholic
                            church. The lord started talking to me and I started to pray together with ten
                            other brothers. The Lord anointed me with His Holy Spirit and gave me a thirst
                            for His word. I started spending more and more time in prayer and bible reading.
                            At this point God spoke to me and told me to leave the catholic church and to
                            start a new church. It was the days when I had no idea about what it was to start
                            a church but I heard God’s voice,” WILL YOU SHEPHERD MY FLOCK. “to which I
                            answered,” LORD IF YOU LEAD ME I WILL.” The Lord said,” I WILL LEAD YOU,”
                            and with that assurance I dedicated myself to the Lord in fulltime ministry.
                        </p>
                        <p className="pastor-testimony-details">
                            And thus in the year 1991 the JESUS WITH US CHURCH was born in tondiarpet
                            with just 10 brothers.In the same year the group of 10 quickly became 25.
                            Initially a brother opened up his own house and we met at the house for
                            Sunday services. But this small group of 25 grew steadily and became 150
                            and we had to move from the house to a rented place. It was a small and
                            simple place with a thatched roof. God saw out plight and gave us a new
                            place in the main road in tondiarpet in 1996. On the personal front God
                            miraculously healed me from many health conditions and in 1993 gave me a
                            godly wife S.Rosy who has a heart for the ministry and three wonderful
                            children (Blessy, Rufus and Alice) who are also serving the Lord alongside
                            me in fulfilling His vision in varying capacities. I thank God for His
                            grace in my life.The church began to grow significantly from 150 to 900
                            by 2012 and God through His mighty arm brought us to a broad place where
                            we have been coming together to worship the Lord. Currently the number
                            of people attending Sunday services ranges around 1500 believers from
                            600 families. Our vision and my goal is to garner 10,000 souls to Christ
                            and to build a church building for HIM. By God’s grace I have also been
                            given the responsibility as a Regional Pastor for all the Jesus With Us
                            churches in Chennai.
                        </p>
                        <p className="pastor-testimony-details">
                            The God who said “I will Lead” has been true to HIS word.
                            I know He will continue to lead me. I can surely say that God has
                            led me all the way for the past 25 years. He has been with us in the highs
                            and He has been with us through the lows carrying us all the way like a father
                            who carries his child. He has been with us when we passed through the
                            desert and He has been with us when we passed through deep waters.
                        </p>
                        <p className="pastor-testimony-details">
                            He has been my Good Shepherd and my one true guide and I can say along with
                            St. Paul,<b>” I am confident of this very thing that he who has begun a
                            good work in me will complete it until the day of Jesus Christ” Phil. 1:6</b>
                        </p>
                        <p className="pastor-testimony-details">
                            To God alone be the Praise, the Honour and the Glory. Amen
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PastorsTestimony;