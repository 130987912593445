import React, { Component } from 'react'
import '../Media/Media.css'
import Footer from '../../components/Layout/Footer'
import Aux from '../../hoc/Aux'
import Underline from '../../asserts/images/header_line.svg'
import AudioSongs from '../../components/MediaSubComponents/AudioSongs/AudioSongs'
import VideoSongs from '../../components/MediaSubComponents/VideoSongs/VideoSongs'
import Sermons from '../../components/MediaSubComponents/Sermons/Sermons'

class Media extends Component {
    constructor(props) {
        super(props);
        this.state = {
            audioComponent: true,
            videoComponent: false,
            sermons: false,
            isActiveAudio: true,
            isActiveVideo: false,
            isActiveSermons: false
        };
        this.audioSongs = this.audioSongs.bind(this);
        this.videoSongs = this.videoSongs.bind(this);
        this.sermons = this.sermons.bind(this);
    }

    audioSongs() {
        this.setState({
            audioComponent: true,
            videoComponent: false,
            sermons: false,
            isActiveAudio: true,
            isActiveVideo: false,
            isActiveSermons: false

        });

    }

    videoSongs() {
        this.setState({
            audioComponent: false,
            videoComponent: true,
            sermons: false,
            isActiveAudio: false,
            isActiveVideo: true,
            isActiveSermons: false
        });
    }

    sermons() {
        this.setState({
            audioComponent: false,
            videoComponent: false,
            sermons: true,
            isActiveAudio: false,
            isActiveVideo: false,
            isActiveSermons: true
        });
    }

    render() {
        const { isActiveAudio, isActiveVideo, isActiveSermons } = this.state;

        return (

            <Aux>
                <section className="m-custom">
                    <header className="header-media">
                        <div className="col-12 media-tag">
                            <div className="media-tag-text">
                                <p className="p-2">MEDIA</p>
                            </div>
                        </div>
                    </header>
                </section>

                <section>
                    <div className="container-fluid spx-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center">
                                    <div className="d-inline-block">
                                        <div className="media-title">MEDIA MINISTRIES</div>
                                        <img className="title-underline-img" src={Underline} alt="underline" />
                                        <p className="media-subtitle mt-3">
                                            Sing to the Lord, all the earth; For the Lord is great
                                            and greatly to be praised; He is also to be feared above all gods. 1 Chr 16:23,25.
                                </p>
                                    </div>
                                </div>

                                <p className="media-details">
                                    <span className="text-cus-color">Our Pastor C Swamydoss by grace God Almighty got the gift of writing new songs
                        and compose tunes as per the word of God</span><i> “Sing to the LORD a new song; sing to the LORD, all the earth.
                                    Sing to the LORD, praise His name; proclaim His salvation day after day.
                                    Declare His glory among the nations,
                        His wonderful deeds among all peoples - Psalm 96:1,2,3”</i> <span className="text-cus-color"> Initially on days of audio
                        cassette we releases songs album in the name of <b>Aradhanai Geethangal, </b>
                        later we have been releasing audio and video album in the name of <b>Uyire</b> </span>
                                </p>
                            </div>
                        </div>

                        <div className="row justify-content-center my-5">
                            <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-xs-12 text-center">
                                <div className="media-buttons">
                                    <button type="button" onClick={this.audioSongs} className={`btn btn-transperant btn-lg  ${isActiveAudio ? 'media-button' : null}`} > AUDIO SONGS</button>
                                    <button type="button" onClick={this.videoSongs} className={`btn btn-transperant btn-lg ${isActiveVideo ? 'media-button' : null}`}>VIDEO SONGS</button>
                                    <button type="button" onClick={this.sermons} className={`btn btn-transperant btn-lg ${isActiveSermons ? 'media-button' : null}`}>SERMONS</button>
                                </div>

                                <div className="media-icons-sm">
                                    <span  onClick={this.audioSongs} className={`icon ${isActiveAudio ? 'icon-audio-active ' : 'icon-audio'}`} ></span>
                                    <span  onClick={this.videoSongs} className={`icon ${isActiveVideo ? 'icon-video-active ' : 'icon-video'}`}></span>
                                    <span  onClick={this.sermons} className={`icon ${isActiveSermons ? 'icon-sermons-active ' : 'icon-sermons'}`}></span>
                                </div>

                            </div>


                        </div>

                        {this.state.audioComponent ? <AudioSongs /> : null}
                        {this.state.videoComponent ? <VideoSongs /> : null}
                        {this.state.sermons ? <Sermons /> : null}

                    </div>
                </section>
                <Footer />
            </Aux>

        )
    }
}

export default Media;