import React, { Component } from 'react';
import '../PrayerRequest/PrayerRequest.css';
import '../../responsive.css'
import Underline from '../../asserts/images/header_line.svg';
import Footer from '../../components/Layout/Footer';
import Aux from '../../hoc/Aux';
import Axios from 'axios';
export const AppContext = React.createContext();


class PrayerRequest extends Component {
    static contextType = AppContext;   
    
    insertUser = (event) => {
        event.preventDefault();
        event.persist();
        Axios.post('http://jesuswithusministries.com/api/prayer_request.php',{
            user_name:this.username.value,
            user_email:this.useremail.value,
            user_mobile:this.usermobile.value,
            user_prayer:this.prayerpoints.value
        })
        .then(function ({data}) {
            if(data.success === 1){
                event.target.reset();
                 alert(data.msg);
            }
            else{
                alert(data.msg);
            }
        }.bind(this))
        .catch(function (error) {
        console.log(error);
        });

    }
    render() {
        return (
            <Aux>
                <section className="m-custom">
                    <header className="header-prayer">
                        <div className="col-12 prayer-tag">
                            <div className="prayer-tag-text">
                                <p className="p-2">PRAYER REQUEST </p>
                            </div>
                        </div>
                    </header>
                </section>

                <section className="spx-4 request-form-sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 mb-5">
                                <div className="text-center">
                                    <div className="d-inline-block">
                                        <div className="prayer-title">PRAYER REQUEST FORM</div>
                                        <img className="title-underline-img" src={Underline} alt="underline" />
                                        <p className="prayer-subtitle mt-3">
                                            Pray for one another, that you may be healed. The effective,
                                            fervent prayer of a righteous man avails much. - James 5:16
                                    </p>
                                    </div>
                                </div>

                                <div className="prayer-details">
                                    Be stay and strong in the faith, our God Almighty can supply all your needs.
                                    He is King of Kings and Lord of Lords nothing is
                                    impossible for Him as He created this whole world by His word.
                                </div>

                                <div className="prayer-details mt-2">
                                    Kindly fill the below prayer request and send to us,
                                    our prayer warrior will be supporting you in their prayers and supplications
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                                <form onSubmit={this.insertUser} method="post">
                                    <div className="prayer-form">
                                        <div className="form-group row">
                                            <label htmlFor="username" className="col-sm-3 col-form-label">Name</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="username" id="username" ref={(val) => this.username = val} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="prayer-form">
                                        <div className="form-group row">
                                            <label htmlFor="useremail" className="col-sm-3 col-form-label">Email</label>
                                            <div className="col-sm-9">
                                                <input type="email" className="form-control" name="useremail" id="useremail" ref={(val) => this.useremail = val} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="prayer-form">
                                        <div className="form-group row">
                                            <label htmlFor="usermobile" className="col-sm-3 col-form-label">Mobile</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" name="usermobile" id="usermobile" ref={(val) => this.usermobile = val} />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="prayer-form">
                                        <div className="form-group row">
                                            <label htmlFor="prayerpoints" className="col-sm-3 col-form-label">Prayer Points</label>
                                            <div className="col-sm-9">
                                                <textarea className="form-control" rows="5" cols="10" id="prayerpoints" name="prayerpoints" ref={(val) => this.prayerpoints = val}> </textarea>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="form-group row my-5">
                                        <div className="col-sm-12 btn-align">
                                            <button type="submit" className="btn btn-primary">SEND</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer />
            </Aux>

        )
    }
}

export default PrayerRequest;